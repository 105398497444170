<template>
  <div>
    <div class="tabs is-boxed" v-if="vin">
      <ul>
        <li
          :class="{
            'is-active':
              active === 'MarketOverview' && permissions.similarListingExplorer
          }"
        >
          <a @click="toggleContent('MarketOverview')">
            <span>Market overview</span>
          </a>
        </li>
        <li
          :class="{
            'is-active': active === 'VehicleInfoTimeline'
          }"
        >
          <a @click="toggleContent('VehicleInfoTimeline')">
            <span>Vehicle info & timeline</span>
          </a>
        </li>
      </ul>
    </div>
    <div class="is-fullheight">
      <component :is="active" v-bind="{ vrm }" :load="timeline" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'CompanionContent',
  components: {
    VehicleInfoTimeline: () =>
      import('modules/vehicle-profile/components/VehicleInfoTimeline'),
    MarketOverview: () =>
      import('modules/companion/components/section/CompanionMarketOverview')
  },
  data: () => ({ active: 'MarketOverview' }),
  async mounted() {
    await this.setLocalFilters()
    if (!this.isOutdated) {
      this.clearAndFetch()
    }
  },
  computed: {
    ...mapGetters('companion', ['timeline', 'vin', 'vrm', 'isOutdated']),
    ...mapGetters('auth', ['permissions'])
  },
  methods: {
    ...mapActions({
      clearAndFetch: 'companion/similarListings/clearAndFetch',
      setLocalFilters: 'companion/similarListings/filterBy/setLocalFilters',
      getTimeline: 'companion/getTimeline'
    }),
    toggleContent(content) {
      if (content === 'VehicleInfoTimeline' && this.timeline === null) {
        this.getTimeline(this.$route.params.uuid)
      }

      this.active = content
      this.$mxp.track(
        `companion_${
          content === 'VehicleInfoTimeline'
            ? 'VehicleTimeline'
            : 'CompanionListings'
        }_toggle`
      )
    }
  }
}
</script>
